import { Icon } from "@iconify/react";

const getIcon = (name) => < Icon icon = { name }
width = { 22 }
height = { 22 }
/>;

// eslint-disable-next-line consistent-return
const sidebarConfig = (user) => {
    let addMenus = [];
    const sideBarListMenu = [{
        title: "Dashboard",
        path: "/dashboard",
        icon: getIcon("carbon:dashboard"),
    }, ];
    if (Object.keys(user).length > 0) {
        addMenus = [{
                title: "Logbooks",
                icon: getIcon("akar-icons:book-open"),
                children: [{
                        title: "Logbook",
                        path: "/logbooks",
                        icon: getIcon("system-uicons:files-multi"),
                    },
                    {
                        title: "Input",
                        path: "/logbooks/input",
                        icon: getIcon("carbon:document"),
                    },
                ],
            },
            {
                title: "Document",
                icon: getIcon("akar-icons:check-box"),
                children: [{
                        title: "Private Document",
                        path: "/docsUser",
                        icon: getIcon("system-uicons:files-multi"),
                    },
                    {
                        title: "Learning Hours",
                        path: "/jampelajaran",
                        icon: getIcon("system-uicons:files-multi"),
                    },
                ],
            },
            {
                title: "SPT Generator",
                path: "/sptGenerator",
                icon: getIcon("ep:document-copy"),
            },
        ];

        if (user.role === "superadmin") {
            let menuAdmin = [{
                title: "Admin",
                icon: getIcon("wpf:administrator"),
                children: [{
                        title: "Public Document",
                        path: "/docsUsers",
                        icon: getIcon("carbon:document"),
                    },
                    {
                        title: "Monitor JP",
                        path: "/monitorjp",
                        icon: getIcon("carbon:document"),
                    },
                ],
            }, ];
            addMenus = [...addMenus, ...menuAdmin];
        }
    }
    console.log();
    return [...sideBarListMenu, ...addMenus];
};

export default sidebarConfig;