import { Link as RouterLink, useParams } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography, Alert } from "@mui/material";
// layouts
import { useSelector } from "react-redux";
import { BASE_PATH } from "src/lib/lib.uri";
import AlertMui from "../../components/atoms/Alert/AlertMui";
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/molecules/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/_authentication/login";
import AuthSocial from "../../components/_authentication/AuthSocial";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
export default function PageLogin() {
  const { statusemail } = useParams();
  const register = useSelector((state) => state.auth.register);
  return (
    <RootStyle title="Login">
      <AuthLayout>
        Don’ t have an account ? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Register Account
        </Link>
      </AuthLayout>
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Si Dawai!
          </Typography>
          <img
            src={`${BASE_PATH}/static/illustrations/illustration_login.png`}
            alt="login"
          />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to DAWAI
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Log in using the administrative office account.
            </Typography>
          </Stack>
          {register && (
            <AlertMui
              status="success"
              message="Registration Complete, Please Check Your Email"
            />
          )}
          {statusemail === "confirmed" && (
            <AlertMui status="success" message="Account Already Use, Login!" />
          )}
          <LoginForm />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’ t have an account ?
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
